

import {getJoinPlaysType, getLotteryPlaysType, JoinGuessBoxSucType} from "@/Api/Other/indexType";
import Storage from "@/util/Storage";
import {getGuessBoxMess, getLotteryPlays} from "@/Api/Other";

// 获取 盒中 用户信息
export function getJoinUserList(body:JoinGuessBoxSucType) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(getGuessBoxMess({ userId,token },body).then(res=>{
        return res.data
    }))
}

// 获取 中奖的盒子 用户信息
export function getLotteryBox(body:getLotteryPlaysType) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(getLotteryPlays({ userId,token },body).then(res=>{
        return res.data
    }))
}
