

import Axios from "../Config/shop"
import {
    createGameType,
    getJoinPlaysType, getLotteryPlaysType,
    HeadType, isLotteryGuessBoxType,
    JoinGuessBoxSucType,
    joinPlaysType, updateGameType,
    updateJoinPlaysType
} from "@/Api/Other/indexType";


// 获取参加盲盒 信息
export function joinGuessBoxSuc( headers:HeadType,body:JoinGuessBoxSucType ):Promise<any>{
    return (
        Axios({
            url:"/app/blind/box/person/personNumber",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取当前游戏
export function getPresentPlays( headers:HeadType ):Promise<any>{
    return (
        Axios({
            url:"/app/blind/box/queryGame",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 加入游戏
export function joinPlays( headers:HeadType,body:joinPlaysType ):Promise<any>{
    return (
        Axios({
            url:"/app/blind/box/person/addGameUser",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改加入选中的盒子 游戏
export function updateJoinPlays( headers:HeadType,body:updateJoinPlaysType ):Promise<any>{
    return (
        Axios({
            url:"/app/blind/box/person/updateBoxGame",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取 参与游戏 自己选中的 列表
export function getJoinPlays( headers:HeadType,body:getJoinPlaysType ):Promise<any>{
    return (
        Axios({
            url:"/app/blind/box/person/myBoxGame",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查看盒中用户信息
export function getGuessBoxMess( headers:HeadType,body:JoinGuessBoxSucType ):Promise<any>{
    return (
        Axios({
            url:"/app/blind/box/person/queryBoxUserInfo",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取 中奖 游戏 用户列表
export function getLotteryPlays( headers:HeadType,body:getLotteryPlaysType ):Promise<any>{
    return (
        Axios({
            url:"/app/blind/box/person/winnerList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查看盒中用户信息
export function isLotteryGuessBox( headers:HeadType,body:isLotteryGuessBoxType ):Promise<any>{
    return (
        Axios({
            url:"/app/blind/box/isReward",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 自增 观看人员
export function autoWatchPerson( headers:HeadType ):Promise<any>{
    return (
        Axios({
            url:"/app/blind/box/accessNumber",
            method:"POST",
            headers,
            data:{}
        })
    )
}

/**   盲盒瞎猜 管理数据 接口   */

// 自增 观看人员
export function createGame( headers:HeadType,body:createGameType ):Promise<any>{
    return (
        Axios({
            url:"/app/blind/box/createGame",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改状态
export function updateGame( headers:HeadType,body:updateGameType ):Promise<any>{
    return (
        Axios({
            url:"/app/blind/box/updateGameStatus",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取抽奖池中 信息
export function getJoinList( headers:HeadType,body:JoinGuessBoxSucType ):Promise<any>{
    return (
        Axios({
            url:"/app/blind/box/person/boxPersonNumber",
            method:"POST",
            headers,
            data:body
        })
    )
}
